import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import './element/index.css'
import "@/css/font.css";
import Vant from "vant";
import "vant/lib/index.css";
import * as filters from '@/util/filters' // 引入工具类

Object.keys(filters).forEach(key => {
  // 注册过滤器
  Vue.filter(key, filters[key])
})
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
// 或者使用配置参数来初始化，详情见文档
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Vant);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
