
import Vue from "vue";
import VueRouter from "vue-router";
import { getStore } from "@/util/store";
let isWx = window.location.hostname === 'pay.wtjy.com' // 是否为微信环境
// let isWx = true

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    redirect: isWx ? '/orderList' : '/workbench',
    children: [
      {
        path: "/workbench",
        name: "workbench",
        meta: {
          url: "/workbench",
          title: "工作台"
        },
        component: () => import("@/views/workbench/index.vue"),
      },
      {
        path: "/order/userPrintItem",
        name: "userPrintItem",
        meta: {
          url: "/order",
          title: "用户打印列表",
          path: "/order/userPrintItem",
        },
        component: () => import("@/views/order/index.vue"),
      },
      {
        path: "/order",
        name: "order",
        meta: {
          url: "/order",
          title: "门店"
        },
        component: () => import("@/views/order/index.vue"),
        redirect: '/order/userPrintItem',
        children: [
      
        
          {
            path: "/orderDetails",
            name: "orderDetails",
            meta: {
              url: "/order",
              title: "订单详情",
              subTitle: "用户打印列表 / 订单详情",
              path: "/userPrintItem",
            },
            component: () => import("@/views/order/details/index.vue"),
          },
        ]
      },
      // 终端
      {
        path: "/terminal",
        name: "terminal",
        meta: {
          url: "/terminal",
          title: "终端"
        },
        component: () => import("@/views/terminal/index.vue"),
        redirect: '/terminal/terminalManagement',
        children: [
          {
            path: "/terminal/terminalManagement",
            name: "terminalManagement",
            meta: {
              url: "/terminal",
              title: "打印终端",
              path: "/terminal/terminalManagement",
            },
            component: () => import("@/views/terminal/management/index.vue"),
          },
          {
            path: "/terminal/printer",
            name: "printer",
            meta: {
              url: "/terminal",
              title: "打印机",
              path: "/terminal/printer",
            },
            component: () => import("@/views/terminal/printer/index.vue"),
          },

        ]
      },
      // 门店
      {
        path: "/shop",
        name: "shop",
        meta: {
          url: "/shop",
          title: "门店"
        },
        component: () => import("@/views/shop/index.vue"),
        redirect: '/shop/shopDetails',
        children: [
          {
            path: "/shop/shopDetails",
            name: "shopDetails",
            meta: {
              url: "/shop",
              title: "门店详情"
            },
            component: () => import("@/views/shop/details/index.vue"),
          },
          {
            path: "/shop/merchandiseInventory",
            name: "merchandiseInventory",
            meta: {
              url: "/shop",
              title: "平台商品库"
            },
            component: () => import("@/views/shop/merchandiseInventory/index.vue"),
          },
          {
            path: "/shop/myCommodity",
            name: "myCommodity",
            meta: {
              url: "/shop",
              title: "我的商品"
            },
            component: () => import("@/views/shop/myCommodity/index.vue"),
          },
          {
            path: "/shop/staffManagement",
            name: "staffManagement",
            meta: {
              url: "/shop",
              title: "员工管理"
            },
            component: () => import("@/views/shop/staffManagement/index.vue"),
          },
          {
            path: "/shop/passwordAdmin",
            name: "passwordAdmin",
            meta: {
              url: "/shop",
              title: "密码设置"
            },
            component: () => import("@/views/shop/passwordAdmin/index.vue"),
          },
          {
            path: "/shop/authentificationOfUser",
            name: "authentificationOfUser",
            meta: {
              url: "/shop",
              title: "认证列表"
            },
            component: () => import("@/views/shop/authentificationOfUser/index.vue"),
          },
        ]
      },
    ]
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/pay",
    name: "wechat-web-pay",
    meta: {
      name: "班级收款",
    },
    component: () => import("@/views/pay/index.vue"),
  },
  {
    path: "/payPage",
    name: "wechat-web-pay-page",
    meta: {
      name: "班级收款",
    },
    component: () => import("@/views/pay/pay.vue"),
  },
  {
    path:'/paySuccess',
    name: "wechat-web-pay-success",
    meta: {
      name: "支付成功",
    },
    component: () => import("@/views/pay/paySuccess.vue"),
  },
  {
    path: "/orderList",
    name: "wechat-web-orderList",
    meta: {
      name: "订单管理",
    },
    component: () => import("@/views/pay/orderList.vue"),
  },
];

const router = new VueRouter({
  routes,
});
function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}
function replaceWechatRedirectUri() {
  const w = location.href.indexOf("?");
  const j = location.href.indexOf("#");
  if (w !== -1 && j > w && getQueryVariable("state")) {
    const state = getQueryVariable("state").split("#")[0];
    const url =
      location.origin +
      `/#/pay?code=${getQueryVariable("code")}&state=${state}`;
    location.replace(url);
    return true;
  }
}
router.beforeEach((to, from, next) => {
  document.title = to.meta.name
  if (['/pay', '/payPage','/paySuccess'].includes(to.path)) {
    if (replaceWechatRedirectUri()) {

      return false;
    } else {
      next()
    }

  } else {

    // console.log(to)
    if (to.meta.title) {
      document.title = `智慧文印 | ${to.meta.title}`;
    } else {
      document.title = "智慧文印";
    }

    // 判断路由跳转  如果用户两小时未操作  清楚缓存
    // const time = getStore({ name: "lastRequestTime" });
    // const newTime = parseInt(new Date().getTime() / 1000);
    // if (!time) {
    //   clearStore({ type: 1 });
    //   clearStore();
    // } else if (newTime - Number(time) > 7200) {
    //   clearStore();
    //   clearStore({ type: 1 });
    // }
    // 特殊判断不需要登录
    if (to.meta.routeWhitelist) {
      next();
      return;
    }
    const userInfo = getStore({ name: "userinfo" });

    if (to.name != "login") {
      if (userInfo && userInfo.userId) {
        next();
      } else {
        next({ path: "/login" });
      }
    } else if (userInfo && userInfo.userId) {
      next({ name: "home" });
    } else {
      next();
    }

  }

});
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router;
